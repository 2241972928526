import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

const formatPort = ({
  portKrName,
  portEnName,
  code,
}: {
  portKrName: string | undefined;
  portEnName: string | undefined;
  code: string | undefined;
}) =>
  `${replaceEmptyToDash(portKrName || portEnName)} (${replaceEmptyToDash(
    code
  )})`;

export { formatPort };
