import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Trans } from "react-i18next";
import Router from "next/router";
import { useTranslation } from "next-i18next";
import { useRecoilValue } from "recoil";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { APP_REGION } from "@sellernote/_shared/src/constants";
import AUTH_QUERY from "@sellernote/_shared/src/queries/forwarding/AUTH_QUERY";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { addEventToGTMDataLayer } from "@sellernote/_shared/src/utils/common/gtm";
import { validateEmail } from "@sellernote/_shared/src/utils/common/validation";
import Modal from "@sellernote/_sds-v2/src/components/Modal";

import Styled from "./index.styles";

export default function GetCouponForm() {
  const { t } = useTranslation(["common-new", "common"]);

  const isAuthenticated = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const {
    isLoading,
    mutate: checkRegisteredEmail,
    ResponseHandler: ResponseHandlerOfCheckRegisteredEmail,
  } = AUTH_QUERY.useCheckRegisteredEmail({
    Trans,
    onSuccess: (res) => {
      // result 가 true 일 때, 가입했던 유저 (로그인 창). false 일 때, 가입하지 않은 유저 (회원가입으로 이동).
      if (!res.result) return handleNotRegisteredUser();

      return setOpensAlreadyRegisteredModal(true);
    },
  });

  const [email, setEmail] = useState("");
  const [isFocusEmailInput, setIsFocusEmailInput] = useState(false);

  const [opensAlreadyRegisteredModal, setOpensAlreadyRegisteredModal] =
    useState(false);

  const isValidateEmail = useMemo(
    () =>
      validateEmail({
        val: email,
        allowEmpty: false,
        Trans,
      }).result,
    [email]
  );

  const handleSubmitEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!isValidateEmail) return;

    if (e.key !== "Enter") return;

    handleSubmit();
  };

  const handleSubmit = () => {
    localStorage.setItem("email", email);

    checkRegisteredEmail({ pathParams: { email } });
  };

  const handleNotRegisteredUser = useCallback(() => {
    addEventToGTMDataLayer({
      event: "main_hero_email_submit",
    });

    Router.push("/register");
  }, []);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim());
  };

  return (
    <>
      <Styled.getCouponFormWrapper>
        <Loading active={isLoading} label={t("main:subscription.loading")} />

        <Styled.getCouponDesc>
          <Trans i18nKey="main-landing:인트로섹션_쿠폰_설명" />
        </Styled.getCouponDesc>

        <Styled.getCouponForm>
          <Styled.registerEmailInput
            type="text"
            placeholder={
              isFocusEmailInput ? "" : t("main-landing:인트로섹션_쿠폰_이메일")
            }
            value={email}
            onChange={handleEmailChange}
            onKeyPress={handleSubmitEnter}
            onFocus={() => setIsFocusEmailInput(true)}
            onBlur={() => setIsFocusEmailInput(false)}
            disabled={isAuthenticated || isLoading}
            region={APP_REGION}
          />

          <Styled.registerButton
            type="button"
            className="register-button"
            onClick={handleSubmit}
            disabled={!isValidateEmail || isAuthenticated || isLoading}
            region={APP_REGION}
          >
            {t("main-landing:인트로섹션_쿠폰_버튼")}
          </Styled.registerButton>
        </Styled.getCouponForm>

        {(isFocusEmailInput || (!!email && !isValidateEmail)) && (
          <Styled.inputEmailGuide>
            {t("main-landing:인트로섹션_쿠폰_이메일")}
          </Styled.inputEmailGuide>
        )}
      </Styled.getCouponFormWrapper>

      {ResponseHandlerOfCheckRegisteredEmail}

      <Modal
        uiType="titleAndBody"
        title={<Trans i18nKey="main-landing:인트로섹션_쿠폰_가입된_이메일" />}
        body={<Trans i18nKey="main-landing:인트로섹션_쿠폰_로그인_안내" />}
        active={opensAlreadyRegisteredModal}
        actionPositive={{
          label: t("main-landing:인트로섹션_쿠폰_확인_버튼"),
          handleClick: () => {
            setOpensAlreadyRegisteredModal(false);

            Router.push("/login");
          },
        }}
      />
    </>
  );
}
