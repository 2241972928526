import { SendRequestPayloadConsolidationItem } from "@sellernote/_shared/src/types/forwarding/consolidation";
import {
  AirEachCargoDetail,
  AirTotalCargoDetail,
  ConsolCargoInfo,
  EachCargoDetail,
  FCLCargoDetail,
  LCLEachCargoDetail,
  LCLTotalCargoDetail,
  ShipmentAddressInfo,
  TotalCargoDetail,
} from "@sellernote/_shared/src/types/forwarding/quote";
import {
  calculateCBM,
  toKg,
  toThousandUnitFormat,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";
import { validMinMaxNumber } from "@sellernote/_shared/src/utils/common/validation";

const getIsValidEachCargoForm = (
  eachCargoFormList: Partial<EachCargoDetail>[]
) => {
  return eachCargoFormList.every(
    ({ name, packagingType, quantity, depth, width, height, weight }) =>
      name &&
      name.length <= 40 &&
      packagingType &&
      validMinMaxNumber(0, 9999, quantity) &&
      validMinMaxNumber(0, 999, depth) &&
      validMinMaxNumber(0, 999, width) &&
      validMinMaxNumber(0, 999, height) &&
      validMinMaxNumber(0, 9999, weight)
  );
};

const getIsValidTotalCargoForm = (
  totalCargoForm: Partial<TotalCargoDetail>
) => {
  const { name, packagingType, volumeAsCbm, weight } = totalCargoForm;

  return !!(
    name &&
    packagingType &&
    validMinMaxNumber(0, 999, volumeAsCbm) &&
    validMinMaxNumber(0, 9999, weight)
  );
};

const getTotalCBMForEach = (
  cargoList: Partial<LCLEachCargoDetail>[],
  type: "lcl" | "air" | "airAsContainer"
) => {
  return cargoList.reduce(
    (acc, { width, height, depth, volumeUnit, quantity }) => {
      if (!width || !height || !depth || !quantity) return acc;

      const calculatedCBM =
        calculateCBM({
          type,
          width,
          height,
          depth,
          sizeUnit: volumeUnit,
        }) || 0;

      return acc + calculatedCBM * Number(quantity);
    },
    0
  );
};

const getTotalCBMForTotal = (cargo: Partial<LCLTotalCargoDetail>) => {
  return Number(cargo.volumeAsCbm ?? 0);
};

const getTotalWeightAsTonForEach = (
  cargoList: Partial<LCLEachCargoDetail>[]
) => {
  return cargoList.reduce((acc, { weight, weightUnit, quantity }) => {
    if (!weight || !weightUnit || !quantity) return acc;

    return acc + toTon(weight, weightUnit) * Number(quantity);
  }, 0);
};

const getTotalWeightAsTonForTotal = (cargo: Partial<LCLTotalCargoDetail>) => {
  if (!cargo.weight || !cargo.weightUnit) return 0;

  return toTon(cargo.weight, cargo.weightUnit);
};

const numberErrorMessage = (maxValue: number, value?: string | number) => {
  if (!value) return;

  const numberValue = Number(value);

  if (numberValue > maxValue)
    return `${toThousandUnitFormat(maxValue)}내로 입력해주세요.`;

  return;
};

/** 화물정보 입력 폼에 값을 하나라도 입력했는지 여부 */
const checkIsEnteredConsolCargoFormValue = (
  cargoDetails: Partial<LCLEachCargoDetail>[] | Partial<LCLTotalCargoDetail>
) => {
  const isLCLEachCargoInfo = Array.isArray(cargoDetails);

  if (isLCLEachCargoInfo) {
    return cargoDetails.some(
      ({ depth, height, name, packagingType, quantity, weight, width }) =>
        Boolean(
          depth ||
            height ||
            name ||
            packagingType ||
            quantity ||
            weight ||
            width
        )
    );
  }

  const { name, packagingType, volumeAsCbm, weight } = cargoDetails;
  return Boolean(name || packagingType || volumeAsCbm || weight);
};

const getConsolidationItemsForEach = (
  cargoDetail: LCLEachCargoDetail,
  exporterId: number,
  addressInfo?: ShipmentAddressInfo
): SendRequestPayloadConsolidationItem => {
  const {
    name,
    packagingType,
    width,
    height,
    depth,
    volumeUnit,
    weight,
    weightUnit,
    quantity,
  } = cargoDetail;

  const { address } = addressInfo ?? {};

  const cbm = getTotalCBMForEach([cargoDetail], "lcl");

  return {
    name,
    address: address?.endAddress,
    details: {
      packagingType,
      cbm: Number(cbm),
      height: Number(height),
      horizontal: Number(width),
      vertical: Number(depth),
      sizeUnit: volumeUnit === "CM" ? "cm" : "m", // consolidation 은 cm / m 단위
      weight: toKg(weight, weightUnit),
      quantity: Number(quantity),
    },
    exporterId,
  };
};

const getConsolidationItemsForTotal = (
  cargoDetail: LCLTotalCargoDetail,
  exporterId: number,
  addressInfo?: ShipmentAddressInfo
): SendRequestPayloadConsolidationItem => {
  const {
    name,
    packagingType,
    volumeAsCbm,
    volumeUnitAsCbm,
    weight,
    weightUnit,
  } = cargoDetail;

  const { address } = addressInfo ?? {};

  return {
    name,
    address: address?.endAddress,
    details: {
      packagingType,
      cbm: Number(volumeAsCbm),
      sizeUnit: volumeUnitAsCbm,
      weight: toKg(weight, weightUnit),
      quantity: 1,
    },

    exporterId,
  };
};

const getFCLCargoFormCalloutMessageList = (fclCargoForm: FCLCargoDetail[]) => {
  const calloutList = new Map<string, string>();

  fclCargoForm.find(({ name, containers }, cargoIndex) => {
    // 화물 품명이 없는 경우 ex) 품목 1 - 품명
    if (!name) {
      calloutList.set("name", `품목 ${cargoIndex + 1} - 품명`);

      return;
    }

    // 컨테이너 카테고리, 타입, 수량 중 하나라도 입력하지 않은 경우
    containers.find(
      ({ containerCategory, containerType, quantity }, containerIndex) => {
        const calloutMessageObject = {
          containerCategory: !containerCategory
            ? `품목 ${cargoIndex + 1} - 컨테이너 ${containerIndex + 1} - 유형`
            : "",
          containerType: !containerType
            ? `품목 ${cargoIndex + 1} - 컨테이너 ${containerIndex + 1} - 사이즈`
            : "",
          quantity:
            !quantity || numberErrorMessage(999, quantity)
              ? `품목 ${cargoIndex + 1} - 컨테이너 ${containerIndex + 1} - 수량`
              : "",
        };

        Object.entries(calloutMessageObject).find(([field, message]) => {
          if (!message) return;

          calloutList.set(field, message);
        });
      }
    );
  });

  return calloutList;
};

const getLCLAndAirCargoFormCalloutMessageList = (
  airCargoForm:
    | Partial<AirEachCargoDetail>[]
    | Partial<LCLEachCargoDetail>[]
    | Partial<AirTotalCargoDetail>
    | Partial<LCLTotalCargoDetail>
) => {
  const calloutList = new Map<string, string>();

  if (Array.isArray(airCargoForm)) {
    airCargoForm.find(
      (
        { name, packagingType, quantity, width, height, depth, weight },
        productIndex
      ) => {
        const calloutMessageObject = {
          name:
            !name || name.length > 40 ? `품목 ${productIndex + 1} - 품명` : "",
          packagingType: !packagingType
            ? `품목 ${productIndex + 1} - 포장타입`
            : "",
          quantity:
            !quantity || numberErrorMessage(9999, quantity)
              ? `품목 ${productIndex + 1} - 포장수량`
              : "",
          width:
            !width || numberErrorMessage(999, width)
              ? `품목 ${productIndex + 1} - 가로`
              : "",
          depth:
            !depth || numberErrorMessage(999, depth)
              ? `품목 ${productIndex + 1} - 세로`
              : "",
          height:
            !height || numberErrorMessage(999, height)
              ? `품목 ${productIndex + 1} - 높이`
              : "",
          weight:
            !weight || numberErrorMessage(9999, weight)
              ? `품목 ${productIndex + 1} - 포장별 중량`
              : "",
        };

        Object.entries(calloutMessageObject).find(([field, message]) => {
          if (!message) return;

          calloutList.set(field, message);
        });
      }
    );
  }

  if (!Array.isArray(airCargoForm)) {
    const { name, packagingType, volumeAsCbm, weight } = airCargoForm;

    const calloutMessageObject = {
      name: !name ? "화물정보 - 품명" : "",
      packagingType: !packagingType ? "화물정보 - 포장타입" : "",
      volumeAsCbm:
        !volumeAsCbm || numberErrorMessage(999, volumeAsCbm)
          ? "화물정보 - 부피"
          : "",
      weight: !weight || (weight ?? 0) > 9999 ? "화물정보 - 중량" : "",
    };

    Object.entries(calloutMessageObject).find(([field, message]) => {
      if (!message) return;

      calloutList.set(field, message);
    });
  }

  return calloutList;
};

const getConsolCargoFormCalloutMessageList = (
  consolCargoForm: ConsolCargoInfo[]
) => {
  const calloutList = new Map<string, string>();

  consolCargoForm.find(({ cargoDetails }, exporterIndex) => {
    if (Array.isArray(cargoDetails)) {
      cargoDetails.find(
        (
          { name, packagingType, width, height, depth, weight, quantity },
          productIndex
        ) => {
          if (calloutList.size > 0) return;

          const calloutMessageObject = {
            name:
              !name || name.length > 40
                ? `수출자 ${exporterIndex + 1} - 품목 ${
                    productIndex + 1
                  } - 품명`
                : "",
            packagingType: !packagingType
              ? `수출자 ${exporterIndex + 1} - 품목 ${
                  productIndex + 1
                } - 포장타입`
              : "",
            quantity:
              !quantity || numberErrorMessage(9999, quantity)
                ? `수출자 ${exporterIndex + 1} - 품목 ${
                    productIndex + 1
                  } - 포장수량`
                : "",
            width:
              !width || numberErrorMessage(999, width)
                ? `수출자 ${exporterIndex + 1} - 품목 ${
                    productIndex + 1
                  } - 가로`
                : "",
            depth:
              !depth || numberErrorMessage(999, depth)
                ? `수출자 ${exporterIndex + 1} - 품목 ${
                    productIndex + 1
                  } - 세로`
                : "",
            height:
              !height || numberErrorMessage(999, height)
                ? `수출자 ${exporterIndex + 1} - 품목 ${
                    productIndex + 1
                  } - 높이`
                : "",

            weight:
              !weight || numberErrorMessage(9999, weight)
                ? `수출자 ${exporterIndex + 1} - 품목 ${
                    productIndex + 1
                  } - 포장별 중량`
                : "",
          };

          Object.entries(calloutMessageObject).find(([field, message]) => {
            if (!message) return;

            calloutList.set(field, message);
          });
        }
      );
    }

    if (!Array.isArray(cargoDetails)) {
      const { name, packagingType, volumeAsCbm, weight } = cargoDetails;

      const calloutMessageObject = {
        name: !name ? `수출자 ${exporterIndex + 1} - 화물정보 - 품명` : "",
        packagingType: !packagingType
          ? `수출자 ${exporterIndex + 1} - 화물정보 - 포장타입`
          : "",
        volumeAsCbm:
          !volumeAsCbm || numberErrorMessage(999, volumeAsCbm)
            ? `수출자 ${exporterIndex + 1} - 화물정보 - 부피`
            : "",
        weight:
          !weight || (weight ?? 0) > 9999
            ? `수출자 ${exporterIndex + 1} - 화물정보 - 중량`
            : "",
      };

      Object.entries(calloutMessageObject).find(([field, message]) => {
        if (!message) return;

        calloutList.set(field, message);
      });
    }
  });

  return calloutList;
};

const checkIsValidEveryValueOfArray = <T>(
  array: T[],
  isTruthy?: (value: T) => boolean
) => {
  if (!Array.isArray(array) || !array.length) return false;

  if (typeof array[0] === "object" && !isTruthy) {
    /** @example checkIsValidEveryValueOfArray(array, ({ value }) => Boolean(value)) */
    throw new Error("배열내 객체에 대해 isTruthy 함수를 제공해야 합니다.");
  }

  return array.every(isTruthy || Boolean);
};

/**
 * 스케줄 선택된 상태로 견적의뢰 페이지로 이동할 수 있는 쿼리스트링이 포함된 URL을 반환한다.
 * - 창고 출발 스케줄인 경우 warehouseId도 넘긴다.
 */
const getURLWithQuerystringForScheduleSelect = ({
  scheduleId,
  warehouseId,
}: {
  scheduleId: number | undefined;
  warehouseId: number | undefined;
}) => {
  if (!scheduleId) {
    return "";
  }

  return `/forwarding/quote?queryStringCase=scheduleSelect&scheduleId=${scheduleId}${
    warehouseId ? `&warehouseId=${warehouseId}` : ""
  }`;
};

export {
  getIsValidEachCargoForm,
  getIsValidTotalCargoForm,
  getTotalCBMForEach,
  getTotalCBMForTotal,
  getTotalWeightAsTonForEach,
  getTotalWeightAsTonForTotal,
  numberErrorMessage,
  checkIsEnteredConsolCargoFormValue,
  checkIsValidEveryValueOfArray,
  getConsolidationItemsForEach,
  getConsolidationItemsForTotal,
  getFCLCargoFormCalloutMessageList,
  getLCLAndAirCargoFormCalloutMessageList,
  getConsolCargoFormCalloutMessageList,
  getURLWithQuerystringForScheduleSelect,
};
