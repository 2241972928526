import Image from "next/image";

import useOnceActivatedIntersectionObserver from "@sellernote/_shared/src/hooks/common/useOnceActivatedIntersectionObserver";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import LinkButton from "@sellernote/_sds-v2/src/components/button/Button/LinkButton";
import ChevronRightIcon from "@sellernote/_sds-v2/src/components/svgIcons/ChevronRightIcon";

import Styled from "./index.styles";

export default function AEOSection() {
  const { isMobile } = useCheckIsMobile();

  const { target, isActivatedTarget } = useOnceActivatedIntersectionObserver({
    threshold: 0.3,
  });

  return (
    <Styled.container ref={target}>
      <Styled.content>
        <Styled.logoWrapper
          className={`${
            isMobile ? `fade-up-animation` : `fade-in-and-spring-animation`
          } ${isActivatedTarget ? "active" : ""}`}
        >
          <Image
            src={
              isMobile
                ? "/assets/images/main/aeo-m.webp"
                : "/assets/images/main/aeo.webp"
            }
            width={isMobile ? 80 : 180}
            height={isMobile ? 80 : 180}
            alt="aeo-logo"
          />
        </Styled.logoWrapper>

        <Styled.contentContainer>
          <Styled.titleAndDescription>
            <h3
              className={`fade-up-animation ${
                isActivatedTarget ? "active" : ""
              }`}
            >
              안전하고 간단한 수출입 절차, <br className="mobile-only" />
              관세청이 보장합니다
            </h3>

            <p
              className={`fade-up-animation ${
                isActivatedTarget ? "active" : ""
              }`}
            >
              국내 디지털 포워딩 스타트업 최초 관세청이 주도하는{" "}
              <br className="mobile-only" />
              AEO 인증으로 신속한 수출입 절차에 안전성을 더했습니다.
              <br />
              보안·안전이 중요한 공급망, 물류 위험 관리를 쉽다와 함께 해보세요.
            </p>
          </Styled.titleAndDescription>

          <LinkButton
            className={`fade-up-animation ${isActivatedTarget ? "active" : ""}`}
            theme="tertiary"
            label="자세히 보기"
            width="137px"
            borderType="outlined"
            iconInfo={{
              Icon: ChevronRightIcon,
              position: "right",
            }}
            size={isMobile ? "small" : "normal"}
            href="https://ship-da.com/blog/aeo-%EB%9C%BB-%ED%98%9C%ED%83%9D%EB%B6%80%ED%84%B0-%EA%B4%80%EC%84%B8%EC%B2%AD-%EC%9D%B8%EC%A6%9D-%ED%8F%AC%EC%9B%8C%EB%94%A9-%EC%97%85%EC%B2%B4%EC%9D%98-%EC%9E%A5%EC%A0%90%EC%9D%80--25395"
          />
        </Styled.contentContainer>
      </Styled.content>
    </Styled.container>
  );
}
