import { Dispatch, SetStateAction, useEffect, useState } from "react";

type SetValue = Dispatch<SetStateAction<string>>;

/**
 * 이전에 배포된 코드가 JSON.stringify를 사용하고 있어서 string 타입만을 다루는 useLocalStorage를 추가함
 */
export default function useLocalStorageByString(
  key: string,
  initialValue: string
): [string, SetValue] {
  const readValue = (): string => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? item : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);

      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<string>(readValue);

  const setValue: SetValue = (value) => {
    if (typeof window == "undefined") {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }

    try {
      const newValue = value instanceof Function ? value(storedValue) : value;

      window.localStorage.setItem(key, newValue);
      setStoredValue(newValue);
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  return [storedValue, setValue];
}
