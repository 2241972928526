import React, { Dispatch, SetStateAction } from "react";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import DiscountRateContent from "./DiscountRateContent";
import TitleAndCta from "./TitleAndCta";
import TitleAndCtaForMobile from "./TitleAndCtaForMobile";
import Styled from "./index.styles";

export default function MainSectionIntroB({
  setAbTestType,
}: {
  setAbTestType: Dispatch<SetStateAction<string>>;
}) {
  const { isMobile } = useCheckIsMobile();

  return (
    <Styled.container>
      {isMobile ? <TitleAndCtaForMobile /> : <TitleAndCta />}

      <DiscountRateContent setAbTestType={setAbTestType} />
    </Styled.container>
  );
}
