import styled, { css, keyframes } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setElevationStyle } from "@sellernote/_sds-v2/src/styles/elevation";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(150px);
  }

  100% {
    opacity: 1;
  }
`;

const sectionIntroWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  ${mobile(css`
    /** 모바일 환경에서 진입했을 때 여백이 보이지 않도록 수정 (모바일 헤더 높이를 Viewport에서 제외) */
    min-height: calc(100vh - 48px);
    max-height: 960px;
    text-align: start;
  `)};

  .subtitle {
    margin-top: 16px;
    ${setFontStyle("SubHead1")};

    ${mobile(css`
      margin-top: 8px;
      ${setFontStyle("Body4")};
      /** wh_80을 사용해달라는 디자인 요구사항 */
      color: ${COLOR.wh_80};
    `)};
  }
`;

const backgroundImage = styled.div`
  position: absolute;
  background-color: #4e6e73; // 배경 이미지가 로딩되기 전, 이미지와 비슷한 컬러로 먼저 보여주기 위한 컬러라 디자인시스템에 없는 컬러를 사용.
  width: 100%;
  height: calc(100vh - 150px);
  max-height: 960px;
  z-index: -1;

  ${mobile(css`
    /** 모바일 환경에서 진입했을 때 여백이 보이지 않도록 수정 (모바일 헤더 높이를 Viewport에서 제외) */
    min-height: calc(100vh - 48px);
  `)};
`;

const sectionIntro = styled.section`
  position: absolute;
  min-width: 570px;
  width: 100%;
  max-width: 1132px;
  top: calc(50% - 150px);
  left: 154px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;

  ${mobile(css`
    top: 0px;
    left: 0px;
    min-width: 360px;
    width: 100%;
    align-items: flex-start;
    position: relative;
  `)};

  > * {
    color: ${COLOR.wh};
    ${setElevationStyle("level2", "text-shadow")}
  }

  > h3 {
    ${mobile(css`
      margin-top: 48px;
      ${setFontStyle("SubHead2")};
      padding-left: 24px;

      /* 디자이너 요청에 의한 커스텀 폰트 스타일 */
      font-size: 22px;
      line-height: 30px;
    `)};
  }

  > .coupon-form {
    margin-top: 60px;

    ${mobile(css`
      margin-top: 32px;
      padding: 0px 24px;
      animation-delay: 0.2s;
    `)};
  }

  .fade-up-animation {
    ${mobile(css`
      opacity: 0;

      &.active {
        animation-name: ${fadeUp};
        animation-duration: 0.8s;
        animation-timing-function: ease-in-out, cubic-bezier(0.65, 0, 0.35, 1);
        animation-fill-mode: forwards;
      }
    `)};
  }
`;

const desc = styled.p`
  margin-top: 24px;
  ${setFontStyle("SubHead2")};

  ${mobile(css`
    ${setFontStyle("SubHead4")};
    animation-delay: 0.3s;
  `)};
`;

const getCouponFormWrapper = styled.div``;

const getCouponDesc = styled.div`
  ${setFontStyle("SubHead2")};

  ${mobile(css`
    ${setFontStyle("SubHead3")};
  `)};
`;

const getCouponForm = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;

  ${mobile(css`
    margin-top: 8px;
  `)};
`;

const registerEmailInput = styled.input<{ region: SellernoteAppRegion }>`
  border-radius: 4px 0px 0px 4px;
  ${setFontStyle("Body2")};
  width: 400px;
  padding: 20px 16px;
  background-color: ${COLOR.wh};
  color: ${COLOR.grayScale_700};

  &:disabled {
    background-color: ${COLOR.wh};
  }

  &::placeholder {
    color: ${COLOR.grayScale_400};
  }

  ${mobile(css<{ region: SellernoteAppRegion }>`
    width: ${({ region }) => (region === "KR" ? "198px" : "204px")};
    padding: 12px 13px;
  `)};
`;

const registerButton = styled.button<{ region: SellernoteAppRegion }>`
  display: flex;
  justify-content: center;
  background-color: ${COLOR.primary_400};
  color: ${COLOR.wh};
  border-radius: 0px 4px 4px 0px;
  border: 0;
  ${setFontStyle("Body2")};
  padding: 20px 16px;
  width: ${({ region }) => (region === "KR" ? "150px" : "145px")};
  cursor: pointer;

  &:disabled {
    background-color: ${COLOR.grayScale_300};
    color: ${COLOR.wh};
    cursor: not-allowed;
  }

  ${mobile(css<{ region: SellernoteAppRegion }>`
    width: ${({ region }) => (region === "KR" ? "114px" : "108px")};
    padding: 15px 12px;
    ${setFontStyle("Body4")};
  `)};
`;

const inputEmailGuide = styled.div`
  margin-top: 8px;
  ${setFontStyle("SubHead2")};
  color: ${COLOR.wh};
  text-align: left;

  ${mobile(css`
    margin-top: 4px;
    position: absolute;
    ${setFontStyle("SubHead3")};
    /** 폰트 사이즈 12px로 고정해달라는 디자인 요구사항 */
    font-size: 12px;
  `)};
`;

const scrollIconLine = keyframes`
  0% {
    transform: scaleY(0.82) translateY(0px);
  }

  100% {
    transform: scaleY(1.81) translateY(3px);
  }
`;

const scrollIconArrow = keyframes`
  0% {
    transform: translateY(-9.8px);
  }

  100% {
    transform: translateY(3px);
  }
`;

const scrollIcon = styled.div`
  position: absolute;
  bottom: 16px;
  left: calc(50% - 26px);
  cursor: pointer;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .scroll-icon-arrow-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 24px;

    .scroll-icon-line,
    .scroll-icon-arrow {
      position: absolute;
    }

    .scroll-icon-line {
      width: 1.58px;
      height: 12.29px;
      top: 0;
      animation-name: ${scrollIconLine};
      animation-duration: 1s;
      animation-timing-function: ease-in-out, cubic-bezier(0.65, 0, 0.35, 1);
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }

    .scroll-icon-arrow {
      width: 10px;
      height: 5.74px;
      transform: translateY(-8px);
      animation-name: ${scrollIconArrow};
      animation-duration: 1s;
      animation-timing-function: ease-in-out, cubic-bezier(0.65, 0, 0.35, 1);
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }
  }

  .scroll-text {
    margin-top: 3px;
    ${setFontStyle("SubHead3")};
    color: ${COLOR.wh};
  }
`;

const videoDimmedLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
`;

const videoWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

const video = styled.video`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

export default {
  sectionIntroWrapper,
  sectionIntro,
  backgroundImage,
  desc,
  getCouponFormWrapper,
  getCouponDesc,
  getCouponForm,
  registerEmailInput,
  registerButton,
  inputEmailGuide,
  scrollIcon,
  videoDimmedLayer,
  videoWrapper,
  video,
};
