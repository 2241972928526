import React from "react";
import dynamic from "next/dynamic";

import ScheduleSearchBarForDesktop from "../../../../containers/searchBarForSchedule/ScheduleSearchBarForDesktop";

import Styled from "./index.styles";

const Video = dynamic(() => import("./Video"), {
  ssr: false,
});

function TitleAndCta() {
  return (
    <Styled.sectionIntroWrapper>
      <Styled.sectionIntro>
        <Styled.mainDescription>
          <h2>
            <span className="line">투명한 일정과&nbsp;</span>
            <span className="line">합리적인 운임으로</span>
            <br className="only-mobile" /> 물류를 쉽게
          </h2>

          <p className="desc">
            쉽다 포워딩 전문가가 직접 제공하는
            <br className="only-mobile" /> 디지털 포워딩 서비스를 지금
            경험해보세요!
          </p>
        </Styled.mainDescription>

        <Styled.ctaContainer>
          <ScheduleSearchBarForDesktop isLanding />
        </Styled.ctaContainer>
      </Styled.sectionIntro>

      <Video />
    </Styled.sectionIntroWrapper>
  );
}

export default TitleAndCta;
