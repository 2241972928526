import styled, { css } from "styled-components";

const registrationWrapper = styled.div<{ abTestType: string }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  ${({ abTestType }) => {
    if (abTestType === "B") {
      return css`
        transition: transform 0.3s ease-in-out;
        transform: translateY(100%);

        &.is-visible {
          transform: translateY(0);
        }
      `;
    }
  }}
`;

export default {
  registrationWrapper,
};
