import React from "react";
import Image from "next/image";

import ScheduleSearchBarForMobile from "../../../../../containers/searchBarForSchedule/ScheduleSearchBarForMobile";

import Styled from "./index.styles";

export default function TitleAndCtaForMobile() {
  return (
    <Styled.introBSection>
      <Styled.backgroundImageWrapper>
        <Image
          src="/assets/images/main/intro-video-mobile-test-type-b.png"
          alt="background-image"
          layout="fill"
          objectFit="cover"
          placeholder="blur"
          blurDataURL="/assets/images/main/intro-video-mobile-blur-data.png"
          priority
        />
      </Styled.backgroundImageWrapper>

      <Styled.textContainer>
        <Styled.title>
          투명한 일정과
          <br />
          합리적인 운임으로
          <br />
          물류를 쉽게
        </Styled.title>

        <Styled.description>
          쉽다 포워딩 전문가가 직접 제공하는
          <br />
          디지털 포워딩 서비스를 지금 경험해보세요!
        </Styled.description>
      </Styled.textContainer>

      <Styled.searchBarWrapper>
        <ScheduleSearchBarForMobile />
      </Styled.searchBarWrapper>
    </Styled.introBSection>
  );
}
