import React from "react";
import { scroller as ReactScroller } from "react-scroll";
import dynamic from "next/dynamic";
import Image from "next/image";
import { Trans } from "next-i18next";

import useOnceActivatedIntersectionObserver from "@sellernote/_shared/src/hooks/common/useOnceActivatedIntersectionObserver";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import CommonStyled from "@sellernote/shipda-kr/src/components/landing/index.styles";

import GetCouponForm from "./GetCouponForm";
import Styled from "./index.styles";

const Video = dynamic(() => import("./Video"), {
  ssr: false,
});

export default function MainSectionIntroA() {
  const { isMobile } = useCheckIsMobile();

  const handleNextSectionMove = () => {
    ReactScroller.scrollTo("sub-section", {
      duration: 500,
      delay: 10,
      smooth: true,
      offset: -70,
    });
  };

  const { target, isActivatedTarget } = useOnceActivatedIntersectionObserver({
    threshold: 0.3,
  });

  return (
    <Styled.sectionIntroWrapper ref={target}>
      <Styled.sectionIntro>
        <CommonStyled.sectionTitle
          className={`fade-up-animation ${isActivatedTarget ? "active" : ""}`}
        >
          <Trans
            i18nKey="main-landing:인트로섹션_타이틀"
            components={{
              mobilebr: <br className="only-mobile" />,
            }}
          />

          <div
            className={`fade-up-animation ${
              isActivatedTarget ? "active" : ""
            } subtitle`}
          >
            <Trans i18nKey="main-landing:인트로섹션_부제" />
          </div>
        </CommonStyled.sectionTitle>

        <div
          className={`coupon-form fade-up-animation ${
            isActivatedTarget ? "active" : ""
          }`}
        >
          <GetCouponForm />
        </div>

        {isMobile && (
          <>
            <Styled.backgroundImage>
              <Image
                src={"/assets/images/main/intro-video-mobile.webp"}
                alt="background-image"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
                blurDataURL="/assets/images/main/intro-video-mobile-blur-data.png"
                priority
              />
            </Styled.backgroundImage>
          </>
        )}
      </Styled.sectionIntro>

      {!isMobile && (
        <Styled.scrollIcon onClick={handleNextSectionMove}>
          <div className="scroll-icon-mouse">
            <Image
              src={"/assets/images/main/intro-scroll-icon-mouse.webp"}
              alt="scroll-icon"
              width={21.8}
              height={31.26}
            />
          </div>

          <div className="scroll-icon-arrow-container">
            <div className="scroll-icon-line">
              <Image
                src={"/assets/images/main/intro-scroll-icon-line.webp"}
                alt="scroll-icon"
                width={1.58}
                height={12.29}
              />
            </div>

            <div className="scroll-icon-arrow">
              <Image
                src={"/assets/images/main/intro-scroll-icon-arrow.webp"}
                alt="scroll-icon"
                width={10}
                height={5.74}
              />
            </div>
          </div>

          <div className="scroll-text">SCROLL</div>
        </Styled.scrollIcon>
      )}

      {!isMobile && <Video />}
    </Styled.sectionIntroWrapper>
  );
}
